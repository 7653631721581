import React from 'react';

import classnames from 'classnames';

import styles from './index.module.less';

const Button = (props) => {
  const {
    children,

    width,

    size = 'large',

    type = 'primary',

    radius = '',

    disabled = false,

    noBorder,

    styles: stylesProp,

    ...restProps
  } = props;

  const btnSty = classnames(styles.btn, {
    [styles.large]: size === 'large',

    [styles.middle]: size === 'middle',

    [styles.small]: size === 'small',

    [styles.tiny]: size === 'tiny',

    [styles.primary]: type === 'primary',
    [styles.ghost]: type === 'ghost',

    [styles.normal]: type === 'normal',

    [styles.blue]: type === 'blue',

    [styles.error]: type === 'error',

    [styles.orange]: type === 'orange',

    [styles.light]: type === 'light',

    [styles.bigRadius]: radius === 'big',

    [styles.noBorder]: noBorder,
  });

  return (
    <button
      style={{ width, ...stylesProp }}
      type="button"
      disabled={disabled}
      className={btnSty}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
